<template>
  <fragment>
    <navbar />
    <div
      class="page-header position-relative"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/header_02.jpg') + ')',
        backgroundSize: 'cover',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <video loop muted autoplay preload="auto">
        <source :src="require('@/assets/img/bg.mp4')" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div class="container pb-10 pb-lg-9 pt-12 postion-relative z-index-2">
        <div class="row mb-7">
          <div class="mx-auto text-center col-md-6 mt-4">
            <h3 class="text-white">Mais autonomia para seu negócio</h3>
            <p class="text-white">
              Conheça a solução completa em autosserviço.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-n8 container">
      <div class="">
        <div class="row">
          <div class="mb-4 col-lg-4 mb-lg-0">
            <pricing-card
              style="min-height: 480px"
              :price="{
                currency: '',
                value: 'Autosserviço',
                detail: 'Com segurança e praticidade',
              }"
              :specifications="[
                { label: 'Suporte 24x7', includes: true },
                { label: 'Aplicativo de Gestão', includes: true },
                { label: 'Verificação Maiores 18 anos', includes: true },
                { label: 'Relatórios de Consumo', includes: true },
                { label: 'Integração Nota Fiscal', includes: true },
                { label: 'Customização da Interface', includes: false },
              ]"
              :action="{
                color: 'success',
                route: 'https://api.whatsapp.com/send/?phone=5544999191795',
                label: 'Entre em contato',
              }"
            />
          </div>
          <div class="mb-4 col-lg-8 mb-lg-0">
            <YouTube
              src="https://youtu.be/PEByv7ASiy8"
              @ready="onReady"
              ref="youtube"
              width="100%"
              height="480"
              :vars="{ autoplay: 0, controls: 0, enablejsapi: 1 }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container" id="onde-estamos">
      <div class="row">
        <div class="mt-8 mx-auto text-center col-md-8">
          <h2>Onde estamos?</h2>
          <p>
            Iris Pay já é aceito em bares, restaurantes, pubs, cervejarias,
            shopping centers e condomínios espalhados por todo território
            nacional. A maior concentração de estabelecimentos é na região sul e
            sudeste, mas já estamos presentes em todas as regiões e quase todos
            os estados brasileiros. Veja no mapa abaixo as regiões com maior
            concentração de estabelecimentos parceiros.
          </p>
        </div>
      </div>
      <div class="card">
        <div class="p-3 card-body">
          <vue-google-heatmap
            :points="pins"
            width="100%"
            :height="750"
            :initial-zoom="5"
            :lat="-16.6406263"
            :lng="-54.6994753"
            :map-style="mapStyle"
            :options="{
              radius: 1.5,
              opacity: 0.3,
              dissipating: false,
            }"
          />
        </div>
      </div>
    </div>
    <div class="container my-6" id="nossos-clientes">
      <div class="row">
        <div class="mt-8 mx-auto text-center col-8">
          <h2>Quem são nossos clientes?</h2>
          <h6 class="opacity-5">
            Mais de 50 cervejarias, bares, pubs e condomínios confiam na Iris
            Pay, confira alguns dos nossos clientes:
          </h6>
        </div>
      </div>

      <div class="card gradient mt-4">
        <div class="card-body">
          <div class="row">
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-arteza.png"
                alt="Logo Arteza"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-barbacana.png"
                alt="Logo Barbacana"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-brewtainer.png"
                alt="Logo Brewtainer"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-campinas.png"
                alt="Logo Campinas"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-chopp-box.png"
                alt="Logo Chopp Box"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-cuscuz.png"
                alt="Logo Cuscuz"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-fabrica.png"
                alt="Logo Fábrica"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-wayne.png"
                alt="Logo Wayne"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-zuma.png"
                alt="Logo Zuma"
              />
            </div>
            <div class="mb-4 col-lg-2 col-md-3 col-4">
              <img
                class="w-100 opacity-2 grayscale"
                src="@/assets/img/logos/logo-luggo.png"
                alt="Logo Luggo"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="container my-6" id="nossos-clientes">
        <div class="mt-8 row">
          <div class="mx-auto text-center col-md-6">
            <h2>Perfil do Consumidor</h2>
            <p>
              A maior concentração do público que utiliza o app Iris Pay está na
              faixa de 26 a 35 anos, sendo mais de 50% dos usuários abaixo de 35
              anos.
            </p>
          </div>
          <reports-doughnut-chart
            title="Consumidores por faixa etária"
            tooltip="See the consumption per room"
            :count="{ number: 92000, text: 'Usuários ativos' }"
            :chart="{
              labels: [
                '18 a 25 anos',
                '26 a 35 anos',
                '36 a 45 anos',
                '46 a 55 anos',
                '56 a 65 anos',
                '66 a 75 anos',
              ],
              datasets: {
                label: 'Consumidores',
                data: [26, 38, 24, 8, 3, 1],
              },
            }"
          />
        </div>
      </div>-->
    </div>
    <div class="container my-6" id="como-funciona">
      <div class="row">
        <div class="mt-8 mx-auto text-center col-md-6">
          <h2>Como funciona?</h2>
          <p>
            É muito simples: baixe o app, faça o cadastro, adicione créditos e
            se divirta!
          </p>
        </div>
      </div>

      <div class="mt-3 row">
        <div class="col-lg-6 col-12" style="min-height: 250px">
          <carousel
            :items="[
              {
                img: image1,
                title: 'Baixe o App',
                description:
                  'Disponível em versões Android e iOS no Google Play e AppStore.',
                icon: {
                  component: 'ni ni-cloud-download-95 text-dark',
                  background: 'bg-white',
                },
              },
              {
                img: image2,
                title: 'Cadastre seu usuário',
                description: 'Definindo seu e-mail e senha.',
                icon: {
                  component: 'ni ni-circle-08 text-dark',
                  background: 'bg-white',
                },
              },
              {
                img: image3,
                title: 'Valide seus documentos',
                description:
                  'Anexando uma foto do seu RG ou CNH e tirando uma selfie em movimento.',
                icon: {
                  component: 'ni ni-badge text-dark',
                  background: 'bg-white',
                },
              },
              {
                img: image4,
                title: 'Adicione Créditos',
                description: 'Informando os dados do seu Cartão de crédito.',
                icon: {
                  component: 'ni ni-credit-card text-dark',
                  background: 'bg-white',
                },
              },
              {
                img: image5,
                title: 'Clique em comprar',
                description: 'E aponte a câmera para o QR code da torneira.',
                icon: {
                  component: 'ni ni-camera-compact text-dark',
                  background: 'bg-white',
                },
              },
              {
                img: image6,
                title: 'Sirva-se à vontade',
                description:
                  'Volte ao passo anterior sempre que quiser mais. Mas beba com moderação!',
                icon: {
                  component: 'ni ni-satisfied text-dark',
                  background: 'bg-white',
                },
              },
            ]"
          />
        </div>
        <div class="col-12 col-lg-6 mt-4 mt-lg-0">
          <div class="row">
            <transparent-info-card
              :icon="{ component: 'ni ni-cloud-download-95', color: 'warning' }"
              custom-class="my-2"
              description="1. Baixe o app"
              value="AppStore e Google Play"
            />
            <transparent-info-card
              :icon="{ component: 'ni ni-badge', color: 'info' }"
              custom-class="my-2"
              description="2. Cadastre seu usuário"
              value="Informe RG ou CNH"
            />
            <transparent-info-card
              :icon="{ component: 'ni ni-credit-card', color: 'primary' }"
              custom-class="my-2"
              description="3. Adicione Créditos"
              value="Cartão de crédito"
            />
            <transparent-info-card
              :icon="{ component: 'ni ni-camera-compact', color: 'success' }"
              custom-class="my-2"
              description="4. Aponte para o QR code"
              value="Sirva-se à vontade"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container my-6" id="beneficios">
      <div class="row">
        <div class="mt-8 mx-auto text-center col-md-6">
          <h2>Quais são os benefícios?</h2>
          <p>
            Venda mais e com segurança, autonomia, eficiência e agilidade. Veja
            alguns relatos dos nossos clientes:
          </p>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto col-lg-6 col-12 mb-5 mt-1">
          <timeline-list title="Iris Pay é mais...">
            <timeline-item
              :icon="{ component: 'ni ni-watch-time', color: 'success' }"
              title="Rápido, Prático e descomplicado"
              date-time="Sem filas no caixa"
              description="Seus clientes colocam créditos direto no aplicativo e vão utilizando este crédito à medida que se servem e não tem necessidade de fechar a conta no caixa na saída."
              :badges="['Evita filas', 'mais agilidade']"
            />
            <TimelineItem
              :icon="{ component: 'ni ni-money-coins', color: 'danger' }"
              title="Eficiente no atendimento"
              date-time="Menos colaboradores"
              description="Além de proporcionar muito mais autonomia para os consumidores que podem se servir à vontade sem necessidade de chamar o garçom."
              :badges="['Menos colaboradores', 'menos custo']"
            />
            <TimelineItem
              :icon="{ component: 'ni ni-scissors', color: 'info' }"
              title="Consciente e evita desperdício"
              date-time="Não precisa calibrar"
              :description="'Sem necessidade de calibrar, não precisa &quot;sangrar&quot; o barril a cada troca e cada mililitro de chope é contabilizado pelo sistema e apreciado pelos seus clientes.'"
              :badges="['Menos desperdício', 'Não precisa calibrar']"
            />
            <TimelineItem
              :icon="{ component: 'ni ni-chart-bar-32', color: 'warning' }"
              title="Transparente no controle de estoque"
              date-time="Gestão remota"
              description="Você tem visibilidade total de quantos litros ainda tem em cada torneira e pode configurar para ser avisado quando estiver acabando o barril para providenciar a troca. Pode configurar o preço por litro e ativar/desativar torneiras de qualquer lugar."
              :badges="[
                'Controle de estoque',
                'Avisos automáticos',
                'Gestão remota',
              ]"
            />
            <TimelineItem
              :icon="{
                component: 'ni ni-key-25',
                color: 'primary',
              }"
              title="Seguro e confiável"
              date-time="Atende aos requisitos da LGPD"
              description="Possui verificação de identidade e sistema antifraude com validação de documentos e fotos em movimento para evitar cadastros fraudulentos e venda de bebidas a menores de idade."
              :badges="['Segurança', 'Conformidade com LGPD', 'Anti-fraude']"
            />
            <TimelineItem
              :icon="{ component: 'ni ni-shop', color: 'success' }"
              title="Rentável e disponível a qualquer hora"
              date-time="Venda 24h por dia"
              description="Por ser completamente autosserviço pode operar dia e noite sem interrupções e sem necessidade de colaboradores. E seus clientes ainda contam com suporte 24/7 pelo aplicativo."
              :badges="['24 horas']"
            />
          </timeline-list>
        </div>
        <div class="mx-auto text-center col-lg-6 col-12">
          <h2></h2>
          <complex-background-card
            :image="require('@/assets/img/photo_04.jpg')"
            description="Reduz as filas no atendimento para vender mais e atender melhor os seus clientes"
            :action="{ label: '', route: '#contato' }"
          />
          <complex-background-card
            :image="require('@/assets/img/photo_02.jpg')"
            description="Muito mais prático, você se serve quando quiser."
            :action="{ label: '', route: '#contato' }"
          />
          <complex-background-card
            :image="require('@/assets/img/photo_05.jpg')"
            description="Desperdício nunca mais."
            :action="{ label: '', route: '#contato' }"
          />
          <complex-background-card
            :image="require('@/assets/img/photo_06.jpg')"
            description="Tenha controle total de quantos litros tem em cada
          barril."
            :action="{ label: '', route: '#contato' }"
          />
        </div>
      </div>
    </div>
    <div class="container my-6" id="perguntas-frequentes">
      <div class="row">
        <div class="mt-8 mx-auto text-center col-md-6">
          <h2>Perguntas mais frequentes:</h2>
          <p>
            É bom levar em conta algumas dicas para ter mais autonomia na gestão
            do seu estabelecimento e tranquilidade para seus clientes.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto col-md-10">
          <div id="accordionRental" class="accordion">
            <accordion-item
              accordion-id="headingOne"
              collapse-id="collapseOne"
              active
            >
              <template #question>O que precisa para usar?</template>
              <template #answer>
                Basta baixar o aplicativo no Google Play ou AppStore,
                cadastrar-se e adicionar uma forma de pagamento para colocar
                créditos para poder se servir quando e quanto quiser.
              </template>
            </accordion-item>
            <accordion-item accordion-id="headingTwo" collapse-id="collapseTwo">
              <template #question>Como faz para se servir?</template>
              <template #answer>
                No aplicativo, clique em comprar, aponte a câmera do celular
                para o QR Code na tela da torneira desejada e confirme a compra.
                O sistema irá informar que a torneira está liberada para o uso.
                Coloque seu copo embaixo e puxe a alavanca da torneira para se
                servir. Na tela poderá acompanhar a quantidade servida em
                mililitros. Ao terminar de se servir a compra será concluída, o
                valor será debitado dos seus créditos e um cupom fiscal enviado
                para seu e-mail de cadastro (se o estabelecimento optar por essa
                opção).
              </template>
            </accordion-item>

            <accordion-item
              accordion-id="headingThree"
              collapse-id="collapseThree"
            >
              <template #question
                >O que preciso para instalar no meu estabelecimento?</template
              >
              <template #answer>
                O sistema pode ser acoplado na sua câmara fria ou geladeira e
                vem com um display a ser instalado na parte frontal, acima da
                torneira, e duas caixas a serem instaladas na parte interior da
                câmara fria ou geladeira. A primeira caixa contém o hardware
                controlador do sistema e a segunda o medidor de fluxo, que deve
                ser conectado ao barril de chope. O sistema é bivolt automático
                e apenas uma tomada (110v ou 220v) e uma conexão de internet.
              </template>
            </accordion-item>

            <accordion-item
              accordion-id="headingFour"
              collapse-id="collapseFour"
            >
              <template #question>Como é feito a calibração?</template>
              <template #answer>
                O sistema Iris Pay utiliza as mais modernas tecnologias para que
                nunca precise calibrar, mantendo sempre a mais alta precisão na
                medição.
              </template>
            </accordion-item>

            <accordion-item
              accordion-id="headingFive"
              collapse-id="collapseFive"
            >
              <template #question>O sistema causa espuma?</template>
              <template #answer>
                Não, os conectores e material utilizados foram projetados para
                não causar turbulência na linha de bebidas e proporcionar uma
                melhor experiência na extração. Todos os materiais da linha são
                de grau alimentício e aceitam a limpeza padrão utilizada em
                chopeiras, com uso de detergente alcalino clorado.
              </template>
            </accordion-item>

            <accordion-item accordion-id="headingSix" collapse-id="collapseSix">
              <template #question>Posso utilizar qualquer torneira?</template>
              <template #answer>
                Sim, você tem a liberdade de utilizar vários elementos
                diferentes para utilizar o autosserviço da Iris Pay. Porém,
                existem modelos e marcas mais aconselhadas para evitar problemas
                no momento da extração. Consulte nossos especialistas para saber
                tudo sobre torneiras.
              </template>
            </accordion-item>

            <accordion-item
              accordion-id="headingSeven"
              collapse-id="collapseSeven"
            >
              <template #question
                >Consigo saber quanto ainda tem em cada barril?</template
              >
              <template #answer>
                No aplicativo você informa qual cerveja está em cada torneira, o
                custo por litro e o volume do barril conectado. O sistema se
                encarrega de contabilizar quantos litros ainda restam em cada
                barril e te avisa por meio de notificação no seu celular quando
                estiver acabando. Você pode configurar com quantos litros
                restantes quer ser notificado e se programar para trocar o
                barril.
              </template>
            </accordion-item>
            <accordion-item
              accordion-id="headingEight"
              collapse-id="collapseEight"
            >
              <template #question
                >O que minha equipe precisa para operar?</template
              >
              <template #answer>
                O sistema é completamente autônomo, seus clientes podem se
                servir à vontade, sem necessidade de um colaborador no caixa
                para adicionar créditos e sem atendimento para servir. Mas se
                ainda assim preferir ter sua equipe própria de garçons para
                servir, eles podem utilizar o sistema no modo Funcionário e ter
                o controle total sobre quantos litros cada membro da sua equipe
                extraiu e quantos litros ainda restam em cada barril.
              </template>
            </accordion-item>

            <accordion-item
              accordion-id="headingNine"
              collapse-id="collapseNine"
            >
              <template #question
                >Como funciona o suporte da Iris Pay?</template
              >
              <template #answer>
                O suporte Iris Pay é 24/7, ou seja, sua assistência acontece de
                segunda a segunda e 24h por dia para clientes e usuários do
                aplicativo. Nosso suporte inclui toda assistência para o
                sistema, sendo o uso do site, aplicativo e também outras
                necessidades que venham a aparecer como melhores práticas, uso
                de outros elementos, gás, temperaturas entre outros.
              </template>
            </accordion-item>

            <accordion-item accordion-id="headingTen" collapse-id="collapseTen">
              <template #question
                >Como vou garantir que menores de idade não consumam no meu
                estabelecimento?</template
              >
              <template #answer>
                A Iris Pay garante que nenhuma pessoa que não seja validada como
                maior de idade, segundo a lei, possa fazer o compra de bebidas
                alcoólicas, tornando assim o sistema 100% autônomo mais seguro
                que existe para a venda fracionada de bebidas.
              </template>
            </accordion-item>

            <accordion-item
              accordion-id="headingEleven"
              collapse-id="collapseEleven"
            >
              <template #question
                >Como é realizada a verificação de idade dos usuários?</template
              >
              <template #answer>
                A verificação de idade é realizada através de uma prova de vida,
                utilizando a foto de um documento válido, uma selfie e
                comparação dos dados com a Receita Federal garantimos que o
                usuário é maior de idade e seu documento é verdadeiro, tudo isso
                em menos de 2 min. Faça o teste! Baixe o app e valide sua
                maioridade!
              </template>
            </accordion-item>

            <accordion-item
              accordion-id="headingTwelve"
              collapse-id="collapseTwelve"
            >
              <template #question>O sistema é seguro?</template>
              <template #answer>
                Os padrões e protocolos de segurança adotados são semelhante aos
                de bancos, sendo necessário validações reais de maioridade, com
                documentação e prova de vida, ou aprovação de um responsável do
                estabelecimento. Isso faz com que o sistema seja seguro e possa
                ser rastreado algum evento que possa ter acontecido.
              </template>
            </accordion-item>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-6" id="contato">
      <div class="row">
        <div class="mt-8 mx-auto text-center col-md-6">
          <h2>Entre em contato</h2>
          <p>
            Deixe sua mensagem e informações para contato, seja para solicitar
            um orçamento ou saber mais sobre o sistema Iris Pay, estamos à
            disposição para atendê-lo.
          </p>
        </div>
      </div>
      <div class="card">
        <div class="p-3 card-body">
          <div class="row mt-4">
            <div class="col-lg-6 col-12 d-none d-lg-block">
              <img :src="image5" class="shadow-lg img-fluid border-radius-lg" />
            </div>

            <div class="col-lg-6 col-12">
              <div
                class="pipedriveWebForms"
                data-pd-webforms="https://webforms.pipedrive.com/f/5X8h25RbsrATjFhpgADZ568bs6Ozody8foy49RphP2yWN8ohB2pTqmkZeL9zpaggJd"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </fragment>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import AppFooter from "./components/Footer.vue";
import AccordionItem from "./components/AccordionItem.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import PricingCard from "./components/PricingCard.vue";
import TransparentInfoCard from "./components/TransparentInfoCard.vue";
// import ReportsDoughnutChart from "./components/ReportsDoughnutChart.vue";
import Carousel from "././components/Carousel.vue";
import YouTube from "vue3-youtube";
import ComplexBackgroundCard from "./components/ComplexBackgroundCard.vue";
import TimelineList from "./components/TimelineList.vue";
import TimelineItem from "./components/TimelineItem.vue";
// import ContactForm from "./components/ContactForm.vue";

import image1 from "@/assets/img/step_01.jpg";
import image2 from "@/assets/img/step_02.jpg";
import image3 from "@/assets/img/step_03.jpg";
import image4 from "@/assets/img/step_04.jpg";
import image5 from "@/assets/img/step_05.jpg";
import image6 from "@/assets/img/step_06.jpg";

export default {
  name: "HomePage",
  components: {
    Navbar,
    AppFooter,
    PricingCard,
    AccordionItem,
    TransparentInfoCard,
    // ReportsDoughnutChart,
    Carousel,
    YouTube,
    ComplexBackgroundCard,
    TimelineList,
    TimelineItem,
    // ContactForm,
  },
  data() {
    return {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      mapStyle: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "administrative.country",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#181818",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1b1b1b",
            },
          ],
        },
        {
          featureType: "road",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#2c2c2c",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#8a8a8a",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#373737",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#3c3c3c",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry",
          stylers: [
            {
              color: "#4e4e4e",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3d3d3d",
            },
          ],
        },
      ],
      pins: [
        { lat: -23.2831557, lng: -51.122972 },
        { lat: -23.318761, lng: -51.1291204 },
        { lat: -25.4138934, lng: -49.3028419 },
        { lat: -23.3445076, lng: -51.1639976 },
        { lat: -23.3223196, lng: -51.1822118 },
        { lat: -23.2825014, lng: -51.1243884 },
        { lat: -29.9763219, lng: -51.1722838 },
        { lat: -29.7383214, lng: -52.4236225 },
        { lat: -29.4598524, lng: -51.1446214 },
        { lat: -30.007566, lng: -51.1350799 },
        { lat: -29.936765, lng: -51.171803 },
        { lat: -29.9765839, lng: -51.1729091 },
        { lat: -29.2308276, lng: -51.1565612 },
        { lat: -29.9766212, lng: -51.1729704 },
        { lat: -30.0131103, lng: -51.2068839 },
        { lat: -29.4417664, lng: -51.3679914 },
        { lat: -23.3166831, lng: -51.1616883 },
        { lat: -23.329788, lng: -51.1729671 },
        { lat: -22.600287, lng: -48.790182 },
        { lat: -23.2825662, lng: -51.1247597 },
        { lat: -8.0321391, lng: -34.9196074 },
        { lat: -23.4213144, lng: -51.9006218 },
        { lat: -29.724896, lng: -52.431004 },
        { lat: -23.7597746, lng: -53.2993061 },
        { lat: -29.9179544, lng: -51.1581364 },
        { lat: -24.2959285, lng: -53.8434415 },
        { lat: -24.477642, lng: -53.946565 },
        { lat: -13.8110639, lng: -56.0852635 },
        { lat: -30.0046023, lng: -51.0830864 },
        { lat: -21.1249009, lng: -48.9673913 },
        { lat: -24.2847006, lng: -53.8369104 },
        { lat: -22.8227942, lng: -47.0830356 },
        { lat: -24.7191644, lng: -53.7502679 },
        { lat: -27.001167, lng: -51.410251 },
        { lat: -24.3003367, lng: -53.8345112 },
        { lat: -10.2259518, lng: -48.365767 },
        { lat: -24.0823876, lng: -54.2483255 },
        { lat: -29.6152685, lng: -52.1890419 },
        { lat: -28.4881675, lng: -48.7705901 },
        { lat: -24.409048, lng: -53.520024 },
        { lat: -21.748985, lng: -48.1851412 },
        { lat: -16.7166086, lng: -49.279879 },
        { lat: -29.7040911, lng: -52.4296419 },
        { lat: -22.8850694, lng: -47.0543203 },
        { lat: -15.8480539, lng: -48.0121136 },
        { lat: -22.117134, lng: -51.4014686 },
        { lat: -12.256608, lng: -38.928706 },
        { lat: -26.2997387, lng: -48.866883 },
        { lat: -20.7356571, lng: -48.9167365 },
        { lat: -28.1186946, lng: -48.6886849 },
        { lat: -23.0227495, lng: -46.9913287 },
        { lat: -23.3015598, lng: -51.2046879 },
        { lat: -18.8908533, lng: -48.2599801 },
        { lat: -23.990036, lng: -51.8165187 },
        { lat: -20.2727238, lng: -40.2919046 },
        { lat: -23.3168138, lng: -51.1858255 },
        { lat: -24.5614829, lng: -54.0572473 },
        { lat: -25.7484513, lng: -53.0523944 },
        { lat: -21.8357812, lng: -43.3797153 },
        { lat: -21.6982361, lng: -47.4759854 },
        { lat: -28.6789667, lng: -49.3704341 },
        { lat: -20.3120971, lng: -40.2880496 },
        { lat: -29.6810427, lng: -51.4574928 },
        { lat: -24.5642384, lng: -54.0581969 },
        { lat: -24.7117879, lng: -53.7419504 },
        { lat: -24.450849, lng: -54.1649956 },
        { lat: -24.086348, lng: -54.2619777 },
        { lat: -25.7504914, lng: -53.0564821 },
        { lat: -16.6269302, lng: -54.478757 },
        { lat: -23.3078118, lng: -51.1861348 },
        { lat: -23.2762489, lng: -45.6952715 },
        { lat: -23.3078118, lng: -51.1861348 },
        { lat: -21.74706, lng: -41.331213 },
        { lat: -23.875015, lng: -53.8904702 },
        { lat: -23.7886241, lng: -54.2895295 },
        { lat: -23.3341473, lng: -51.1789685 },
        { lat: -23.3345332, lng: -51.1797972 },
        { lat: -29.5510354, lng: -51.9047855 },
        { lat: -30.006243, lng: -51.1931795 },
        { lat: -23.3169955, lng: -51.1856809 },
        { lat: -19.7259958, lng: -50.1930441 },
        { lat: -22.8850287, lng: -47.0543338 },
        { lat: -28.049686, lng: -48.6413652 },
        { lat: -27.902707, lng: -48.5910372 },
        { lat: -24.2847006, lng: -53.8369104 },
        { lat: -22.9726745, lng: -43.3910852 },
        { lat: -22.5155741, lng: -43.187031 },
        { lat: -26.1898926, lng: -49.2672675 },
        { lat: -25.1144614, lng: -50.1568534 },
        { lat: -29.7813443, lng: -55.7892373 },
        { lat: -16.5913089, lng: -49.352426 },
        { lat: -16.7022172, lng: -49.0990844 },
        { lat: -16.7770374, lng: -49.2944025 },
        { lat: -23.3345305, lng: -51.1797972 },
        { lat: -23.9900877, lng: -51.8164366 },
        { lat: -2.5122143, lng: -44.1741988 },
        { lat: -11.8559996, lng: -55.5256689 },
        { lat: -23.0910573, lng: -47.2274959 },
        { lat: -30.023711, lng: -52.9034508 },
        { lat: -23.5426679, lng: -46.7638116 },
        { lat: -26.2691799, lng: -48.849592 },
        { lat: -20.2706774, lng: -40.3024232 },
        { lat: -23.0594469, lng: -47.0296048 },
        { lat: -22.9374323, lng: -47.0659735 },
        { lat: -23.3169955, lng: -51.1856809 },
        { lat: -23.3329372, lng: -51.2005184 },
        { lat: -29.6943849, lng: -53.7463599 },
        { lat: -20.949466, lng: -50.104045 },
        { lat: -22.2317961, lng: -49.9421365 },
        { lat: -26.1506402, lng: -52.8499309 },
        { lat: -21.2137755, lng: -50.460551 },
        { lat: -22.7503633, lng: -47.3046121 },
        { lat: -22.0239874, lng: -47.8935817 },
        { lat: -27.0818394, lng: -53.0049847 },
        { lat: -23.3169955, lng: -51.1856809 },
        { lat: -24.1648875, lng: -54.0971278 },
        { lat: -8.0335053, lng: -50.0342947 },
        { lat: -23.5074961, lng: -47.4667864 },
        { lat: -27.5090178, lng: -48.5154348 },
        { lat: -19.9284279, lng: -43.9605334 },
        { lat: -20.3399408, lng: -40.2858651 },
        { lat: -13.8056396, lng: -56.0859298 },
        { lat: -26.0750054, lng: -53.0471314 },
        { lat: -28.3862347, lng: -53.993835 },
        { lat: -24.5629312, lng: -54.0643047 },
        { lat: -20.8288081, lng: -49.408186 },
        { lat: -29.8885077, lng: -51.1779633 },
        { lat: -26.0749488, lng: -53.046974 },
        { lat: -20.4083232, lng: -41.0536727 },
        { lat: -27.1721504, lng: -51.5204788 },
        { lat: -24.7262068, lng: -53.7387373 },
        { lat: -5.0709987, lng: -42.7824291 },
        { lat: -15.5482516, lng: -54.2986699 },
        { lat: -18.9337633, lng: -48.2801189 },
        { lat: -26.2583054, lng: -53.6229904 },
        { lat: -22.7211214, lng: -47.645094 },
        { lat: -23.3122896, lng: -51.1469415 },
        { lat: -22.4700053, lng: -46.5938117 },
        { lat: -7.1366268, lng: -34.8198553 },
        { lat: -7.1366268, lng: -34.8198553 },
        { lat: -7.1366268, lng: -34.8198553 },
        { lat: -14.4405187, lng: -54.0577247 },
        { lat: -23.5264092, lng: -47.1337302 },
        { lat: -27.1799415, lng: -53.7193529 },
        { lat: -27.5090178, lng: -48.5154348 },
        { lat: -24.2921985, lng: -53.8473858 },
        { lat: -23.4192417, lng: -51.8937875 },
        { lat: -16.7551241, lng: -49.1745288 },
        { lat: -23.1925683, lng: -45.903876 },
        { lat: -7.2240389, lng: -35.8795136 },
        { lat: -7.2240389, lng: -35.8795136 },
        { lat: -7.2240389, lng: -35.8795136 },
        { lat: -23.531982, lng: -47.4605413 },
        { lat: -25.5946934, lng: -48.3811955 },
        { lat: -21.1418149, lng: -47.783643 },
        { lat: -24.5575612, lng: -54.0616645 },
        { lat: -25.7881256, lng: -53.3058784 },
        { lat: -24.9508073, lng: -53.4583534 },
        { lat: -27.857145, lng: -54.473871 },
        { lat: -15.9031549, lng: -52.2469322 },
        { lat: -8.1190079, lng: -34.9015956 },
        { lat: -8.1190079, lng: -34.9015956 },
        { lat: -8.1190079, lng: -34.9015956 },
        { lat: -27.0923721, lng: -53.5965836 },
        { lat: -23.41333, lng: -46.037128 },
        { lat: -22.8850694, lng: -47.0543203 },
        { lat: -22.2598018, lng: -49.9304627 },
        { lat: -15.5589642, lng: -54.3038857 },
        { lat: -23.5140935, lng: -46.7171243 },
        { lat: -24.0024152, lng: -51.3210107 },
        { lat: -23.318761, lng: -51.1291204 },
        { lat: -23.5172133, lng: -47.488947 },
        { lat: -22.9148404, lng: -48.464023 },
        { lat: -9.9643841, lng: -67.849564 },
        { lat: -21.2174588, lng: -50.4148953 },
        { lat: -25.4449042, lng: -52.903866 },
        { lat: -16.4339674, lng: -51.115006 },
        { lat: -16.4339674, lng: -51.115006 },
        { lat: -23.3846652, lng: -51.9250039 },
        { lat: -23.9590417, lng: -46.3498365 },
        { lat: -24.7227347, lng: -53.7408257 },
        { lat: -23.4289559, lng: -51.9308936 },
        { lat: -2.994133, lng: -47.3570357 },
        { lat: -13.0750839, lng: -55.9424151 },
        { lat: -6.0711946, lng: -49.8984717 },
        { lat: -23.3191834, lng: -51.1276497 },
        { lat: -13.5549517, lng: -52.2690478 },
        { lat: -23.2352342, lng: -45.8844558 },
        { lat: -25.4179423, lng: -53.1782271 },
        { lat: -25.0819957, lng: -50.1235937 },
        { lat: -25.3820235, lng: -51.4875608 },
        { lat: -22.8547481, lng: -43.4827415 },
        { lat: -12.9543297, lng: -38.4758934 },
      ],
    };
  },
  methods: {
    onReady() {
      //this.$refs.youtube.playVideo();
    },
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    setNavPills();

    let pipeDriveScript = document.createElement("script");
    pipeDriveScript.setAttribute(
      "src",
      "https://webforms.pipedrive.com/f/loader"
    );
    document.head.appendChild(pipeDriveScript);
  },
  beforeUnmount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    if (this.$store.state.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      this.$store.state.isPinned = true;
    }
  },
};
</script>
