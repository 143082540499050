/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueGoogleHeatmap from "./vendor/vue-google-heatmap";
import VueScrollTo from "vue-scrollto";
import { createGtm } from "vue-gtm";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(VueScrollTo);
appInstance.use(ArgonDashboard);
appInstance.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDseHQi3gPwnQhAkp5C9fYBQy6PdSG9fKE",
    libraries: "visualization",
  },
});
appInstance.use(VueGoogleHeatmap, {
  apiKey: "AIzaSyDseHQi3gPwnQhAkp5C9fYBQy6PdSG9fKE",
});

appInstance.use(
  createGtm({
    id: "GTM-N9G784V", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    // queryParams: {
    //   // Add url query string when load gtm.js with GTM ID (optional)
    //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
    //   gtm_preview: "env-4",
    //   gtm_cookies_win: "x",
    // },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: "2726c7f26c", // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  })
);
appInstance.mount("#app");
