<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->

<template>
  <fragment>
    <div
      v-show="layout === 'landing'"
      class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    ></div>

    <div
      v-if="layout === 'custom' && showSidenav"
      class="position-absolute w-100 min-height-300 top-0"
      :style="{
        'background-image': 'url(' + image + ')',
        'background-position-y': '50%',
      }"
    >
      <span class="mask bg-success opacity-6"></span>
    </div>
    <div
      v-if="showSidenav"
      v-show="layout === 'default'"
      class="min-height-300 position-absolute w-100"
      :class="`${darkMode ? 'bg-transparent' : 'bg-success'}`"
    />

    <aside
      v-if="showSidenav"
      id="sidenav-main"
      class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4"
      :class="` ${sidebarType} ${
        isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'
      } ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}`"
    >
      <div class="sidenav-header">
        <i
          id="iconSidenav"
          class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
          aria-hidden="true"
        ></i>
        <router-link class="m-0 navbar-brand" to="/">
          <img
            :src="
              layout === 'landing' || darkMode || sidebarType === 'bg-default'
                ? logoWhite
                : logo
            "
            class="navbar-brand-img h-100"
            alt="main_logo"
          />
          <span class="ms-2 font-weight-bold">Argon Dashboard 2 PRO</span>
        </router-link>
      </div>
      <hr class="mt-0 horizontal dark" />
      <sidenav-list />
    </aside>
    <main class="main-content position-relative max-height-vh-100 h-100">
      <navbar v-if="showNavbar" />
      <router-view />
      <app-footer v-show="showFooter" />
      <configurator
        :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
      />
    </main>
  </fragment>
</template>
<script>
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapState } from "vuex";
import SidenavList from "@/examples/Sidenav/SidenavList.vue";
import logo from "@/assets/img/logo-ct-dark.png";
import logoWhite from "@/assets/img/logo-ct.png";
import image from "@/assets/img/profile-layout-header.jpg";

export default {
  name: "App",
  components: {
    SidenavList,
    Configurator,
    Navbar,
    AppFooter,
  },
  computed: {
    ...mapState([
      "layout",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "layout",
      "isRTL",
      "sidebarType",
      "darkMode",
    ]),
  },
  data() {
    return {
      logo,
      logoWhite,
      image,
    };
  },
  beforeUnmount() {
    this.$store.state.image;
  },
};
</script>
