<template>
  <div ref="map" :style="`width: ${mapWidth}; height: ${mapHeight}`"></div>
</template>

<script>
import { loaded } from "./loader";

export default {
  name: "vue-google-heatmap",
  props: {
    lat: {
      type: Number,
      default: () => 37.775,
    },
    lng: {
      type: Number,
      default: () => -122.434,
    },
    initialZoom: {
      type: Number,
      default: () => 13,
    },
    mapType: {
      type: String,
      default: () => "roadmap",
    },
    points: {
      type: Array,
      required: true,
    },
    width: {
      type: [String, Number],
      default: () => "100%",
    },
    height: {
      type: [String, Number],
      default: () => "100%",
    },
    mapStyle: {
      type: Array,
      required: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mapWidth() {
      if (typeof this.width === "string") {
        return this.width;
      } else {
        return `${this.width}px`;
      }
    },
    mapHeight() {
      if (typeof this.height === "string") {
        return this.height;
      } else {
        return `${this.height}px`;
      }
    },
    heatmapPoints() {
      return this.points.map((point) => {
        return {
          // eslint-disable-next-line
          location: new google.maps.LatLng(point.lat, point.lng),
          weight: point.weight,
        };
      });
    },
  },
  created() {
    return loaded.then(() => {
      console.log("FdsFdsfsdfsdfsdfsdfdsfsd", this.mapStyle);
      const mapElement = this.$refs.map;
      // eslint-disable-next-line
      this.$mapObject = new google.maps.Map(mapElement, {
        zoom: this.initialZoom,
        center: { lat: this.lat, lng: this.lng },
        mapTypeId: this.mapType,
        mapTypeControl: false,
      });
      // eslint-disable-next-line
      this.$heatmap = new google.maps.visualization.HeatmapLayer({
        data: this.heatmapPoints,
        map: this.$mapObject,
      });

      this.$mapObject.setOptions({ styles: this.mapStyle });
      this.$heatmap.setMap(this.$mapObject);
      this.$heatmap.setOptions(this.options);

      this.$watch("heatmapPoints", (points) => {
        this.$heatmap.setData(points);
      });

      this.$watch(
        "options",
        (options) => {
          this.$heatmap.setOptions(options);
        },
        {
          deep: true,
        }
      );
      this.$watch(
        "mapStyle",
        (options) => {
          this.$heatmap.setOptions(options);
        },
        {
          deep: true,
        }
      );
    });
  },
};
</script>
