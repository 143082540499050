<template>
  <!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mb-4 text-center col-lg-12">
          <router-link
            to="#onde-estamos"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Onde estamos
          </router-link>
          <router-link
            to="#nossos-clientes"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Nossos Clientes
          </router-link>
          <router-link
            to="#como-funciona"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Como funciona
          </router-link>
          <router-link
            to="#beneficios"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Benefícios
          </router-link>
          <router-link
            to="#perguntas-frequentes"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Perguntas Frequentes
          </router-link>
        </div>
        <div class="mx-auto mt-2 mb-4 text-center col-12">
          <a
            href="https://www.facebook.com/AppIrisPay"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-facebook"></span>
          </a>

          <a
            href="https://www.instagram.com/iris.pay/"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-instagram"></span>
          </a>
          <a
            href="https://wa.me/5544999191795"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-whatsapp"></span> (44) 99919-1795
          </a>
          <a
            href="mailto:contato@irispay.com.br"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
            style="white-space: nowrap"
            ><span class="text-lg fa fa-envelope"></span>
            contato@irispay.com.br
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-12">
          <p class="mb-0 text-secondary">
            Copyright © {{ new Date().getFullYear() }} IrisPay - Todos os
            direitos reservados.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
